<script>
import io from "socket.io-client";

export default {
  data() {
    return {
      socket: null,
    };
  },
  async mounted() {
    // Connect to the WebSocket server
    //console.log(io.version);

    this.socket = io("wss://rmobilitytest.raynis.co", {
      path: "/socket.io",
      transports: ["websocket"], // Specify WebSocket as the transport
      reconnectionDelay: 10000, // defaults to 1000
      reconnectionDelayMax: 10000, // defaults to 5000
    });

    // Listen for events
    this.socket.on('connect_error', (err) => {
      console.log('Connect error:', err);
    });
    this.socket.on("connect", () => {
      console.log("Connected to WebSocket server");

      var dataobj = {
        courseId: 4,
        user: 3
      }
      this.socket.emit("dash-join-course", (dataobj))
    
        console.log("then"),

      this.socket.on("newPosition", (data) => {
        console.log("données inside of it", data);
        console.log("Connected new Pos to WebSocket server");
      })

      
    });

    

    //console.log("this.socket", this.socket);






    console.log("after")

    this.socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });


  },
  beforeDestroy() {
    // Close the WebSocket connection when the component is destroyed
    if (this.socket) {
      this.socket.close();
    }
  },
};
</script>
<template>
  <div></div>
</template>
<style></style>